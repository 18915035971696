<div class="app-admin-wrap" [dir]='layoutConf?.dir'>
  <!-- Header for top navigation layout -->
  <!-- ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT -->
  <app-header-top 
    *ngIf="layoutConf.navigationPos === 'top'" 
    [notificPanel]="notificationPanel">
  </app-header-top>
  <!-- Main Container -->
  <mat-sidenav-container 
  [dir]='layoutConf.dir'
  class="app-admin-container app-side-nav-container mat-drawer-transition sidebar-{{layoutConf?.sidebarColor}} topbar-{{layoutConf?.topbarColor}} footer-{{layoutConf?.footerColor}}"
  [ngClass]="adminContainerClasses">
    <mat-sidenav-content>
    <!-- SIDEBAR -->

    
    <!-- Top navigation layout (navigation for mobile screen) -->
    <!-- ONLY REQUIRED FOR **TOP** NAVIGATION MOBILE LAYOUT -->
    <app-sidebar-top *ngIf="layoutConf.navigationPos === 'top' && layoutConf.isMobile"></app-sidebar-top>

    <!-- App content -->
    <div class="main-content-wrap" id="main-content-wrap" [perfectScrollbar]="" [disabled]="layoutConf.topbarFixed || !layoutConf.perfectScrollbar">
      <!-- Header for side navigation layout -->
 

      <div class="rightside-content-hold" id="rightside-content-hold" [perfectScrollbar]="scrollConfig" [disabled]="!layoutConf.topbarFixed || !layoutConf.perfectScrollbar">
        <!-- View Loader -->
        <div class="view-loader" *ngIf="isModuleLoading" style="position:fixed;"
        fxLayout="column" fxLayoutAlign="center center">
          <div class="spinner">
            <div class="double-bounce1 mat-bg-accent"></div>
            <div class="double-bounce2 mat-bg-primary"></div>
          </div>
        </div>
        <!-- View outlet -->
        <div class="container-dynamic">
          <router-outlet></router-outlet>
        </div>
       
       
      </div>
      
      
    </div>
    <!-- View overlay for mobile navigation -->
    <div class="sidebar-backdrop"
    [ngClass]="{'visible': layoutConf.sidebarStyle !== 'closed' && layoutConf.isMobile}"
    (click)="closeSidebar()"></div>
    </mat-sidenav-content>



    
    <egret-notifications2></egret-notifications2>
  </mat-sidenav-container>
</div>
