import { Injectable } from '@angular/core';

declare let gtag:Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  public eventEmitter(eventName: string, eventCategory: string, eventAction: string, eventLabel: string ,  eventValue: number, hasplan : any, campaing : any)
  { 
    gtag('event', eventName, { 
                 eventCategory: eventCategory, 
                 eventLabel: eventLabel, 
                 eventAction: eventAction, 
                 eventToken: eventValue,
                 eventHasPlan : hasplan,
                 eventCampaing : campaing
    })
  }

}
