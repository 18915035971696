import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
export const rootRouterConfig: Routes = [

  {
    path: '',
    redirectTo: 'update/pw/verifyToken',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'update',
        loadChildren: () => import('./views/update-payment/update-payment.module').then(m => m.UpdatePaymentModule)
      }
    ]
  },
  {
    path: '**',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session'}
      }
    ]
  }
];

